// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile #asks{
    width: min(1180px, 90%);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 42px;
    margin-bottom: 220px;
}

.mobile #asks > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.mobile #asks > div > span.span-row{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    font-weight: bolder;
}

.mobile #asks > div > span > .star{
    margin-right: 3.5px;
    width: 17px;
    height: 17px;
    padding: 0 0 8px 0;
}

.mobile #asks > div > span.bottom-text{
    margin-left: 26px;
    font-size: 14.5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Mobile/Asks.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".mobile #asks{\n    width: min(1180px, 90%);\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    gap: 42px;\n    margin-bottom: 220px;\n}\n\n.mobile #asks > div{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 4px;\n}\n\n.mobile #asks > div > span.span-row{\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 15px;\n    font-weight: bolder;\n}\n\n.mobile #asks > div > span > .star{\n    margin-right: 3.5px;\n    width: 17px;\n    height: 17px;\n    padding: 0 0 8px 0;\n}\n\n.mobile #asks > div > span.bottom-text{\n    margin-left: 26px;\n    font-size: 14.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
