import { IconButton } from '@mui/material';
import './Footer.css';
import {navigateToWhatsapp, navigateToTg} from '../Features/openMessangers';

const qrSrc = 'qr.png';
const whatsAppSrc = '/whatsapp.svg';
const tgSrc = '/tg.svg';
const cardsSrc = '/cards.svg';

export default function Footer(){
    return(
        <footer>
            <div>
                <span className='rotated-text'>联络人</span>
                <section>
                    <div className='horizontal'>
                        <img src={qrSrc} alt='qr' className='qr'/>
                        <div>
                            <span>+7 495-152-01-41</span>
                            <div className='img-group'>
                                <IconButton onClick={navigateToWhatsapp}>
                                    <img src={whatsAppSrc} alt="whatsapp" />
                                </IconButton>
                                <IconButton onClick={navigateToTg}>
                                    <img src={tgSrc} alt="telegram" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className='vertical'>
                        <h5>法人和独资经营者的无现金支付</h5>
                        <span>
                            服务以服务的形式提供，不需要在客户端安装软件的特殊操作通过SBERBANK PJSC进行付款
                        </span>
                    </div>
                </section>
                <section>
                    <span>
                    退货服务是官方授权的野莓的伙伴。 有关我们的资料已公布在每个野莓供应商的个人帐户。
                    </span>
                    <div className="vertical">
                        <img src={cardsSrc} alt="cards" className='cards'/>
                    </div>
                </section>
                <section>
                    <span>Copyright @ 2020 COURIER INTEGRATOR LTD. 85 Great Portland Street, London, England, W1W 7LT. All rights reserved</span>
                    <div className="horizontal last-div">
                        <span>用户协议</span>
                        <span>私隐政策</span>
                    </div>
                </section>
            </div>
        </footer>
    )
}