import { Button, IconButton } from '@mui/material';
import {navigateToWhatsapp, navigateToTg} from '../../Features/openMessangers';
import './Content.css';

const atWorkSrc = '/at-work.svg';
const chartsSrc = '/charts.svg';
const whatsAppSrc = '/whatsapp.svg';
const tgSrc = '/tg.svg';
const mailSentSrc = '/mail-sent.svg';
const financeSrc = '/finance.svg';

const smallStarSrc = '/small-star.svg';
const mediumStarSrc = '/medium-star.svg';
const bigStarSrc = '/big-star.svg';

export default function MobileContent(){
    return(
        <>
        
            <div className='wrapper-content pink'>
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-32' 
                style={{top: '143px', right: '17px', zIndex: '3'}}
                />
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-47' 
                style={{bottom: '30px', left: '30px', zIndex: '3'}}
                />
                <div className='block-content'>
                    <span className='rotated'>贸易公司</span>
                    <h2>所有费用由您承担，我们负责销售，并获得公司%或股份</h2>
                    <img src={atWorkSrc} alt='at work' className='at-work content-img'/>
                    <h6>在线销售控制，中文</h6>
                    <h6>每月支付2次收入</h6>
                    <p>合同期2年起，我们建立长期合作伙伴关系</p>
                </div>
            </div>
            <div className='wrapper-content' style={{minHeight: '680px', boxSizing: 'border-box'}}>
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-21 after-rotated' 
                />
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-47' 
                style={{top: '236px', left: '10%'}}/>
                <div className='block-content' style={{gap: '17px'}}>
                    <span className='rotated'>开始销售</span>
                    <h2>在交钥匙市场上启动销售</h2>
                    <h6>在市场上赚钱，我们将处理所有日常工作</h6>
                    <div className='img-group'>
                        <IconButton onClick={navigateToWhatsapp}>
                            <img src={whatsAppSrc} alt="whatsapp" />
                        </IconButton>
                        <IconButton onClick={navigateToTg}>
                            <img src={tgSrc} alt="telegram" />
                        </IconButton>
                    </div>
                    <img src={chartsSrc} alt='charts' className='top-margin content-img' id='charts-img'/>
                </div>
            </div>
            <div className='wrapper-content pink' style={{minHeight: '507px', boxSizing: 'border-box', paddingTop: '91px'}}>
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                style={{top: '30px', right: '30px'}}/>
                <div className='block-content' style={{gap: '16px'}}>
                    <span className='rotated big-rotated'>支持</span>
                    <h2>市场优先支持</h2>
                    <p className='full-width-p'>我们每天在市场上解决许多任务和问题</p>
                    <img src={mailSentSrc} alt='mail sent' className='top-margin content-img' style={{top: '-81px', right: '-166px'}}/>
                    <Button className='small-btn'
                    variant='outlined'
                    >
                        <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                        style={{top: '-30px', left: '-30px'}}/>
                        订阅
                    </Button>
                </div>
            </div>
            <div className='wrapper-content' style={{minHeight: '680px', boxSizing: 'border-box'}}>
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-21 after-rotated' 
                />
                <img src={smallStarSrc} alt="small star" className='absolute-svg width-47' 
                style={{top: '270px', left: '5%'}}/>
                <div className='block-content' style={{gap: '25px'}}>
                    <span className='rotated'>开始销售</span>
                    <h2>在交钥匙市场上启动销售</h2>
                    <p>在市场上赚钱，我们将处理所有日常工作</p>
                    <div className='img-group'>
                        <IconButton onClick={navigateToWhatsapp}>
                            <img src={whatsAppSrc} alt="whatsapp" />
                        </IconButton>
                        <IconButton onClick={navigateToTg}>
                            <img src={tgSrc} alt="telegram" />
                        </IconButton>
                    </div>
                    <img src={financeSrc} alt='finance' className='top-margin content-img' style={{top: '-107px', right: '-61px'}}/>
                </div>
            </div>
        </>
    )
}