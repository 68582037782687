import './OutProjects.css';

const girlSrc = '/human-touch-screen.svg';
const arrowSrc = '/arrow-right.svg';
const wbCarSrc = '/wb-car.svg';
const manSrc = '/man-in-front-of-desk.svg';
const wbSmallSrc = '/wb-small.svg';

const smallStarSrc = '/small-star.svg';
const mediumStarSrc = '/medium-star.svg';
const bigStarSrc = '/big-star.svg';

export default function MobileOutProjects(){
    return(
        <>
            <section id='main'>
                <h2>俄罗斯市场中国销售中心
                    <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                    style={{bottom: '-10px', left: '-20px'}}/>
                </h2>
                <div id='grid'>
                    <div className='wrapper'>
                        <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                        style={{bottom: '-10px', right: '-20px'}}/>
                        <span className='rotated'>选择销售产品</span>
                        <div>
                            <p>我们选择最有利可图的产品在俄罗斯市场上销售</p>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                        style={{bottom: '10px', left: '-30px'}}/>
                        <span className='rotated'>公司注册</span>
                        <img src={girlSrc} alt='girl' className='absolute-img bottom-right'/>
                        <img src={arrowSrc} alt='right arrow' className='arrow arrow-left'/>
                        <div>
                            <p>我们将在俄罗斯注册公司，个体企业家，帮助您保存记录并纳税</p>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <span className='rotated'>销售管理</span>
                        <img src={arrowSrc} alt='right arrow' className='arrow'/>
                        <div>
                            <p>推出广告。处理客户和退货。分析。控制销售、产品周转、仓库余额。</p>
                        </div>
                    </div>

                    <div className='wrapper'>
                        <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                        style={{bottom: '-24px', left: '13px'}}/>
                        <span className='rotated small-text'>WILDBERRIES <br/>START</span>
                        <img src={arrowSrc} alt='right arrow' className='arrow'/>
                        <img src={wbCarSrc} alt='wb car' className='absolute-img top-right' id='wb-car'/>
                        <img src={wbSmallSrc} alt='wb small' className='absolute-img top-right' id='small-wb'/>
                        <div>
                            <p>我们将制作产品卡和展示柜，包装您的货物，并将其交付给仓库和客户。</p>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <img src={smallStarSrc} alt="small star" className='absolute-svg width-21' 
                        style={{bottom: '50px', right: '-27px'}}/>
                        <span className='rotated small-text'>ECOM SECURITY</span>
                        <img src={manSrc} alt='man in front of desk' className='absolute-img top-right' id='man'/>
                        <div>
                            <p>我们保护供应商在市场上的帐户免受欺诈和财务损失</p>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <span className='rotated'>价格管理</span>
                        <img src={arrowSrc} alt='right arrow' className='arrow'/>
                        <div>
                            <p>考虑需求、季节、销售情况，设定和计算优惠价格。</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}