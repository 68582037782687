// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content{
    width: min(1050px, 90%);
    margin: 0 auto;
    position: relative;
}

#title-img{
    width: min(750px, 100%);
}

.content > section#title{
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: min(795px, 100%);
}

#title > h3{
    font-weight: 600;
    font-size: 44px;
}

@media (max-width: 860px){
    #title > h3{
        text-align: center;
    }
}

#monitor{
    position: absolute;
    top: 60px;
    right: -65px;
}

.content > #row{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: min(800px, 90%);
}

.content > #row > div{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.content > #row > div > img{
    flex-basis: 75px;
}

.content > #row > div > p{
    font-size: 21px;
    text-transform: uppercase;
}

@media (max-width: 1150px) {
    #monitor{
        position: relative !important;
        width: 186.6px;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/TitleBlock.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI;QACI,6BAA6B;QAC7B,cAAc;QACd,SAAS;QACT,MAAM;QACN,2BAA2B;IAC/B;AACJ","sourcesContent":[".content{\n    width: min(1050px, 90%);\n    margin: 0 auto;\n    position: relative;\n}\n\n#title-img{\n    width: min(750px, 100%);\n}\n\n.content > section#title{\n    display: flex;\n    flex-direction: column;\n    gap: 0px;\n    width: min(795px, 100%);\n}\n\n#title > h3{\n    font-weight: 600;\n    font-size: 44px;\n}\n\n@media (max-width: 860px){\n    #title > h3{\n        text-align: center;\n    }\n}\n\n#monitor{\n    position: absolute;\n    top: 60px;\n    right: -65px;\n}\n\n.content > #row{\n    display: flex;\n    justify-content: space-evenly;\n    flex-wrap: wrap;\n    width: min(800px, 90%);\n}\n\n.content > #row > div{\n    width: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    flex-direction: column;\n}\n\n.content > #row > div > img{\n    flex-basis: 75px;\n}\n\n.content > #row > div > p{\n    font-size: 21px;\n    text-transform: uppercase;\n}\n\n@media (max-width: 1150px) {\n    #monitor{\n        position: relative !important;\n        width: 186.6px;\n        left: 50%;\n        top: 0;\n        transform: translateX(-50%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
