import MobileAsks from "./Components/Mobile/Asks";
import MobileContent from "./Components/Mobile/Content";
import MobileFooter from "./Components/Mobile/Footer";
import MobileHeader from "./Components/Mobile/Header";
import MobileOutProjects from "./Components/Mobile/OutProjects";
import MobileTitleBlock from "./Components/Mobile/TitleBlock";

export default function MobilePage() {
    return (
        <>
        <MobileHeader/>
        <main className="mobile" style={{marginTop: '130px'}}>
          <MobileTitleBlock/>
          <MobileOutProjects/>
          <h1 className='mobile-title-1'>WB中国区官方合作伙伴 <img src='ok.svg' alt='ok'/> 
          <img src='small-star.svg' alt='small star' className='absolute-svg width-32'
          />
          <img src='small-star.svg' alt='small star' className='absolute-svg width-32'
          />
          </h1>
          <MobileContent/>
          <span className='mobile-title'>
            问题与解答
          </span>
          <MobileAsks/>
        </main>
        <MobileFooter/>
        </>
    );
}