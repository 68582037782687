import { Button, IconButton } from '@mui/material';
import {navigateToWhatsapp, navigateToTg} from '../Features/openMessangers';
import './Content.css';

const atWorkSrc = '/at-work.svg';
const chartsSrc = '/charts.svg';
const whatsAppSrc = '/whatsapp.svg';
const tgSrc = '/tg.svg';
const mailSentSrc = '/mail-sent.svg';
const financeSrc = '/finance.svg';

const smallStarSrc = '/small-star.svg';
const mediumStarSrc = '/medium-star.svg';
const bigStarSrc = '/big-star.svg';

export default function Content(){
    return(
        <>
        
            <div className='wrapper-content pink'>
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '-180px', left: 'calc((100% - 1100px) / 2 + 50px)', zIndex: '3'}}
                />
                <img src={mediumStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '-80px', right: 'calc((100% - 1100px) / 2 + 50px)', zIndex: '3'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '-90px', right: '-70px', transform: 'rotate(105deg)'}}
                />
                <img src={mediumStarSrc} alt="small star" className='absolute-svg' 
                style={{bottom: '10px', left: '10px', transform: 'rotate(120deg)'}}
                />
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '20px', left: 'calc((100% - 1100px) / 2 + 350px)', zIndex: '3', opacity: '0.3', filter: 'brightness(110%)'}}
                />
                <div className='block-content'>
                    <span className='rotated'>贸易公司</span>
                    <h2>所有费用由您承担，我们负责销售，并获得公司%或股份</h2>
                    <img src={atWorkSrc} alt='at work' className='at-work'/>
                    <h6>在线销售控制，中文</h6>
                    <h6>每月支付2次收入</h6>
                    <p>合同期2年起，我们建立长期合作伙伴关系</p>
                </div>
            </div>
            <div className='wrapper-content' style={{minHeight: '680px', boxSizing: 'border-box'}}>
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '80px', left: 'calc((100% - 1100px) / 2 + 80px)', zIndex: '3'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '60px', left: 'calc((100% - 1100px) / 2 - 150px)', zIndex: '3', width: '69px', transform: 'rotate(120deg)'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '-30px', right: '130px', zIndex: '3', width: '69px'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{bottom: '30px', right: 'calc((100% - 1100px) / 2 + 400px)', zIndex: '3', transform: 'rotate(105deg)'}}
                />
                <div className='block-content' style={{gap: '17px'}}>
                    <span className='rotated'>开始销售</span>
                    <h2>在交钥匙市场上启动销售</h2>
                    <img src={chartsSrc} alt='charts' className='top-margin' id='charts-img'/>
                    <h6>在市场上赚钱，我们将处理所有日常工作</h6>
                    <div className='img-group'>
                        <IconButton onClick={navigateToWhatsapp}>
                            <img src={whatsAppSrc} alt="whatsapp" />
                        </IconButton>
                        <IconButton onClick={navigateToTg}>
                            <img src={tgSrc} alt="telegram" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className='wrapper-content pink' style={{minHeight: '507px', boxSizing: 'border-box', paddingTop: '91px'}}>
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '45px', left: 'calc((100% - 1100px) / 2 + 80px)', zIndex: '3'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{bottom: '40px', left: 'calc((100% - 1100px) / 2 - 100px)', zIndex: '3', width: '88px'}}
                />
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '50%', right: 'calc((100% - 1100px) / 2 - 230px)', zIndex: '3'}}
                />
                <div className='block-content' style={{gap: '16px'}}>
                    <span className='rotated'>支持</span>
                    <h2>市场优先支持</h2>
                    <img src={mailSentSrc} alt='mail sent' className='top-margin' style={{top: '-81px', right: '-166px'}}/>
                    <p style={{maxWidth: '100%'}}>我们每天在市场上解决许多任务和问题</p>
                    <Button className='small-btn'
                    variant='outlined'
                    >
                        订阅
                    </Button>
                </div>
            </div>
            <div className='wrapper-content' style={{minHeight: '680px', boxSizing: 'border-box'}}>
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '80px', left: 'calc((100% - 1100px) / 2 + 80px)', zIndex: '3', transform: 'rotate(120deg)'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '43px', left: '30px', zIndex: '3', width: '131.63px'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '50%', left: '50%', zIndex: '3', width: '62.17px', transform: 'translate(-50%, -50%)'}}
                />
                <img src={bigStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '0px', right: '57px', zIndex: '3', width: '59.17px', transform: 'translate(-50%, -50%) rotate(75deg)'}}
                />
                <img src={smallStarSrc} alt="small star" className='absolute-svg' 
                style={{top: '50%', right: '104px', zIndex: '3', width: '37.7px', transform: 'translate(-50%, -50%)'}}
                />
                <div className='block-content' style={{gap: '25px'}}>
                    <span className='rotated'>开始销售</span>
                    <h2>在交钥匙市场上启动销售</h2>
                    <img src={financeSrc} alt='finance' className='top-margin' style={{top: '-107px', right: '-61px'}}/>
                    <h6>在市场上赚钱，我们将处理所有日常工作</h6>
                    <div className='img-group'>
                        <IconButton onClick={navigateToWhatsapp}>
                            <img src={whatsAppSrc} alt="whatsapp" />
                        </IconButton>
                        <IconButton onClick={navigateToTg}>
                            <img src={tgSrc} alt="telegram" />
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    )
}