import './TitleBlock.css';

const titleImgSrc = '/title.png';
const monitorSrc = '/monitor.svg';

const employeeSrc = '/employee.svg';
const miniMonitorSrc = '/monitor-mini.svg';
const wbSrc = '/wb-small.svg';

const smallStarSrc = '/small-star.svg';
const mediumStarSrc = '/medium-star.svg';
const bigStarSrc = '/big-star.svg';
const whiteStarSrc = '/white-star.svg';

export default function MobileTitleBlock(){
    return(
        <>
            <img src={whiteStarSrc} alt="big star" className='absolute-svg width-32 z-index-bigger' 
            style={{top: '96px', left: '10px'}}
            />
            <img src={bigStarSrc} alt="big star" className='absolute-svg width-47' 
            style={{top: '320px', left: '27px'}}
            />
            <img src={whiteStarSrc} alt="big star" className='absolute-svg width-47 z-index-bigger' 
            style={{top: '331px', right: '40px'}}
            />
            <img src={smallStarSrc} alt="big star" className='absolute-svg width-32' 
            style={{top: '630px', left: '57px'}}
            />
            <img src={smallStarSrc} alt="big star" className='absolute-svg width-32' 
            style={{top: '700px', right: '15px'}}
            />
            <div className='content'>
                <section id='title'>
                    <img src={titleImgSrc} alt='title' id='title-img'/>
                    <img src={monitorSrc} alt='monitor' id='monitor'/>
                    <h3>我们按照合作伙伴条款与中国制造商合作</h3>
                </section>
            </div>
        </>
    )
}