import './Asks.css';

const smallStarSrc = '/small-star.svg';

export default function Asks(){
    return(
        <>
            <section id='asks'>
                <div>
                    <span className='span-row'>
                        <img src={smallStarSrc} alt='small star' className='star'/>
                        与你们合作的条件是什么？
                        <ArrowBottom/>
                    </span>
                    <span className='bottom-text'>
                        所有费用由您承担；我们出售货物。您可以选择合作方案，向我们支付一定比例的销售额或净利润份额。
                    </span>
                </div>
                <div>
                    <span className='span-row'>
                        <img src={smallStarSrc} alt='small star' className='star'/>
                        从哪里开始？
                    </span>
                    <span className='bottom-text'>
                    我们共同商定条款，注册公司，您将货物运送到俄罗斯，我们在市场上启动销售
                    </span>
                </div>
                <div>
                    <span className='span-row'>
                        <img src={smallStarSrc} alt='small star' className='star'/>
                        你们是卖给零售买家还是批发买家？
                    </span>
                    <span className='bottom-text'>
                    我们以零售方式销售商品，其价格远远高于他们在俄罗斯向您批发购买商品的价格。主要通过Wildberries和Ozon市场在俄罗斯进行销售
                    </span>
                </div>
                <div>
                    <span className='span-row'>
                        <img src={smallStarSrc} alt='small star' className='star'/>
                        和你合作我能得到什么？
                    </span>
                    <span className='bottom-text'>
                    与我们合作，您将获得：技术、经验、团队和成果。与我们合作，您的销售额将会增加，我们的客户的利润也会增加数倍。我们是一支由营销、销售和 IT 开发专家组成的团队
                    </span>
                </div>
            </section>
        </>
    )
}

function ArrowBottom(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="21" viewBox="0 0 38 21" fill="none">
        <path d="M1 1L19 19L37 1" stroke="black" strokeWidth="2"/>
        </svg>
    )
}