// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#asks{
    width: min(1180px, 90%);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 42px;
    margin-bottom: 220px;
}

#asks > div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

#asks > div > span.span-row{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 30px;
    font-weight: bold;
}

#asks > div > span > .star{
    margin-top: 8px;
    margin-right: 5px;
}

#asks > div > span.bottom-text{
    margin-left: 42px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Asks.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["#asks{\n    width: min(1180px, 90%);\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    gap: 42px;\n    margin-bottom: 220px;\n}\n\n#asks > div{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 20px;\n}\n\n#asks > div > span.span-row{\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 30px;\n    font-weight: bold;\n}\n\n#asks > div > span > .star{\n    margin-top: 8px;\n    margin-right: 5px;\n}\n\n#asks > div > span.bottom-text{\n    margin-left: 42px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
