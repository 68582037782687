import Header from './Components/Header';
import OutProjects from './Components/OutProjects';
import TitleBlock from './Components/TitleBlock';
import './App.css';
import Content from './Components/Content';
import Asks from './Components/Asks';
import Footer from './Components/Footer';
import {MobileView, BrowserView} from 'react-device-detect';
import MobilePage from './MobilePage';

function App() {
  return (
    <>
      <BrowserView>
        <Header/>
        <main style={{marginTop: '130px'}}>
          <TitleBlock/>
          <OutProjects/>
          <h1>WB中国区官方合作伙伴 <img src='ok.svg' alt='ok'/> </h1>
          <Content/>
          <span className='title'>
            问题与解答
          </span>
          <Asks/>
        </main>
        <Footer/>
      </BrowserView>
      <MobileView>
        <MobilePage/>
      </MobileView>
    </>
  );
}

export default App;
