import './TitleBlock.css';

const titleImgSrc = '/title.png';
const monitorSrc = '/monitor.svg';

const employeeSrc = '/employee.svg';
const miniMonitorSrc = '/monitor-mini.svg';
const wbSrc = '/wb-small.svg';

const smallStarSrc = '/small-star.svg';
const mediumStarSrc = '/medium-star.svg';
const bigStarSrc = '/big-star.svg';

export default function TitleBlock(){
    return(
        <>
            <img src={bigStarSrc} alt="big star" className='absolute-svg' 
            style={{top: '51px', left: 'calc((100% - 1170px) / 2)', transform: 'translate(-50%, 15%)'}}
            />
            <img src={mediumStarSrc} alt="medium star" className='absolute-svg hidden-on-560' 
            style={{top: '136px', left: 'calc((100% - 1100px) / 2 + 800px)', transform: 'translate(-50%, 15%)'}}
            />
            <img src={smallStarSrc} alt="small star" className='absolute-svg' 
            style={{top: '418px', left: 'calc((100% - 1200px) / 2)'}}
            />
            <div className='content'>
                <section id='title'>
                    <img src={titleImgSrc} alt='title' id='title-img'/>
                    <img src={monitorSrc} alt='monitor' id='monitor'/>
                    <h3>我们按照合作伙伴条款与中国制造商合作</h3>
                </section>
                <section id='row'>
                    <div>
                        <img src={miniMonitorSrc} alt='mini monitor'/>
                        <p>我们帮助您在市场上全面建立业务、开设商店、仓库、物流</p>
                    </div>
                    <div>
                        <img src={employeeSrc} alt='employee'/>
                        <p>我们负责解决与您在俄罗斯销售相关的所有任务</p>
                    </div>
                    <div>
                        <img src={wbSrc} alt='wb'/>
                        <p>WB中国区官方合作伙伴</p>
                    </div>
                </section>
            </div>
        </>
    )
}