// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile .content{
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 64px;
}

.mobile #title-img{
    width: 100%;
    margin-bottom: 16px;
}

.mobile .content > section#title{
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: min(795px, 100%);
}

.mobile #title > h3{
    font-weight: 600;
    font-size: 4.6vw;
    font-weight: bold;
}

.mobile #monitor{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 232px;
    z-index: 36;
}

.mobile .width-32{
    width: 32px !important;
}

.mobile .width-47{
    width: 47px !important;
}

.mobile .z-index-bigger{
    z-index: 35 !important;
}

.mobile .width-21{
    width: 21px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Mobile/TitleBlock.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,2BAA2B;IAC3B,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".mobile .content{\n    width: 90%;\n    margin: 0 auto;\n    position: relative;\n    margin-bottom: 64px;\n}\n\n.mobile #title-img{\n    width: 100%;\n    margin-bottom: 16px;\n}\n\n.mobile .content > section#title{\n    display: flex;\n    flex-direction: column;\n    gap: 0px;\n    width: min(795px, 100%);\n}\n\n.mobile #title > h3{\n    font-weight: 600;\n    font-size: 4.6vw;\n    font-weight: bold;\n}\n\n.mobile #monitor{\n    position: relative;\n    left: 50%;\n    transform: translateX(-50%);\n    width: 232px;\n    z-index: 36;\n}\n\n.mobile .width-32{\n    width: 32px !important;\n}\n\n.mobile .width-47{\n    width: 47px !important;\n}\n\n.mobile .z-index-bigger{\n    z-index: 35 !important;\n}\n\n.mobile .width-21{\n    width: 21px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
